


const colors = {
    cafe: "#441F04",
    dorado01: "#846312",
    dorado02: "#A08647",
    blanco01: "#FFFFFF",
    blanco02: "#F7F7F7",
    blanco03: "#D6D6D6",
    negro: "#000000",
    gris: "#323232",
    rojo: "#FE534B"
}

export default colors;