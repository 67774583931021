import Queja from "../interfaces/Queja.interface";
import QuejaCompleto from "../interfaces/QuejasCompleto.interface";
import Fecha from "../Tools/Fecha.tool";
// import Tiempo from "../Tools/Tiempo.tool";



const getEstructura = (): Queja => {
    const queja: Queja = {
        quejasDenominacion: "Sinergia Financiera de México, S.A. de C.V., SOFOM, E.N.R.",
        quejasSector: "Sociedades Financieras de Objeto Múltiple E.N.R.",
        quejasNoMes: /*3*/ Fecha.getYearAndMonth(),
        quejasNum: 1,
        quejasFolio: "",
        quejasFecRecepcion: /*"10/03/2024"*/Fecha.getDateToDay(),
        quejasMedio: /*1*/0,
        quejasNivelAT: /*4*/0,
        quejasProducto: /*"126911811258"*/"NAN",
        quejasCausa: /*"08777"*/"0",
        quejasPORI: /*"SI"*/ "NAN",
        quejasEstatus: /*2*/0,
        quejasEstados: /*4*/0,
        quejasMunId: /*2*/0,
        quejasLocId: /*9*/null,
        quejasColId: /*32*/0,
        quejasCP: /*24026*/0,
        quejasTipoPersona: /*1*/0,
        quejasSexo: "NAN",
        quejasEdad: 0,
        quejasFecResolucion: /*"15/03/2024"*/Fecha.getDateToDay(),
        quejasFecNotificacion: /*"15/03/2024"*/Fecha.getDateToDay(),
        quejasRespuesta: /*1*/0,
        quejasNumPenal: /*1*/0,
        quejasPenalizacion: /*1*/0
    };

    return queja;

};

const quejaCompleta = (): QuejaCompleto => {
    const queja: QuejaCompleto = {
        quejasDenominacion: "",
        quejasSector: "",
        quejasNoMes: 0,
        quejasNum: 0,
        quejasFolio: "",
        quejasFecRecepcion: "",
        quejasMedio: 0,
        quejasNivelAT: 0,
        quejasProducto: "",
        quejasCausa: "",
        quejasPORI: "",
        quejasEstatus: 0,
        quejasEstados: 0,
        quejasMunId: 0,
        quejasLocId: 0,
        quejasColId: 0,
        quejasCP: 0,
        quejasTipoPersona: 0,
        quejasSexo: "",
        quejasEdad: 0,
        quejasFecResolucion: "",
        quejasFecNotificacion: "",
        quejasRespuesta: 0,
        quejasNumPenal: 0,
        quejasPenalizacion: 0,
        quejasMedioDescripcion: "",
        quejasNivelATDecripcion: "",
        quejasProductoDescripcion: "",
        quejasCausaDescripcion: "",
        quejasEstadosDescripcion: "",
        quejasMunIdDescripcion: "",
        quejasColDescripcion: ""
    };
    return queja;
}

const castQueja = async (data: Queja): Promise<any> => {
    const queja: any = await {
        QuejasNoMes: +data.quejasNoMes.toString().split("-")[1],
        QuejasFecRecepcion: Fecha.formatDate(data.quejasFecRecepcion),
        QuejasFecResolucion: data.quejasFecResolucion === null ? null : Fecha.formatDate(data.quejasFecResolucion),
        QuejasFecNotificacion: data.quejasFecNotificacion === null ? null : Fecha.formatDate(data.quejasFecNotificacion),
        QuejasEstados: +data.quejasEstados,
        QuejasDenominacion: data.quejasDenominacion,
        QuejasSector: data.quejasSector,
        QuejasNum: data.quejasNum,
        QuejasFolio: data.quejasFolio,
        QuejasMedio: +data.quejasMedio,
        QuejasNivelAT: +data.quejasNivelAT,
        QuejasProducto: data.quejasProducto,
        QuejasCausa: data.quejasCausa,
        QuejasPORI: data.quejasPORI,
        QuejasEstatus: data.quejasEstatus,
        QuejasMunId: +data.quejasMunId,
        QuejasLocId: data.quejasLocId,
        QuejasColId: +data.quejasColId,
        QuejasCP: +data.quejasCP,
        QuejasTipoPersona: data.quejasTipoPersona,
        QuejasSexo: data.quejasSexo,
        QuejasEdad: data.quejasEdad,
        QuejasRespuesta: data.quejasRespuesta === null ? null : +data.quejasRespuesta,
        QuejasNumPenal: +data.quejasNumPenal,
        QuejasPenalizacion: +data.quejasPenalizacion,

    }
    return queja;
}

const validarDatosQueja = (data: Queja): boolean => {
    let value: boolean = true;
    const {
        quejasDenominacion,
        quejasSector,
        quejasNum,
        quejasFolio,
        quejasMedio,
        quejasNivelAT,
        quejasProducto,
        quejasCausa,
        quejasPORI,
        quejasEstatus,
        quejasEstados,
        quejasMunId,
        quejasColId,
        quejasTipoPersona,
        quejasSexo,
        quejasRespuesta,
        quejasNumPenal,
        quejasPenalizacion
    } = data;

    if (quejasDenominacion.length === 0) value = false;
    if (quejasSector.length === 0) value = false;
    if (quejasNum === 0) value = false;
    if (quejasFolio.length === 0) value = false;
    if (quejasMedio === 0) value = false;
    if (quejasNivelAT === 0) value = false;
    if (quejasProducto === "NAN") value = false;
    if (quejasCausa === "0") value = false;
    if (quejasPORI === "NAN") value = false;
    if (quejasEstatus === 0) value = false;
    if (quejasEstados === 0) value = false;
    if (quejasMunId === 0) value = false;
    if (quejasColId === 0) value = false;
    if (quejasTipoPersona === 0) value = false;
    if (quejasSexo === "NAN") value = false;
    if (quejasRespuesta === 0) value = false;
    if (quejasNumPenal === 0) value = false;
    if (quejasPenalizacion === 0) value = false;

    return value;
}


const QuejaModel = {
    getEstructura,
    castQueja,
    validarDatosQueja,
    quejaCompleta,
}

export default QuejaModel