type MesIndex = {
    [key in 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12]: string;
};

const mesIndex: MesIndex = {
    0: "undefined",
    1: "ENERO",
    2: "FEBRERO",
    3: "MARZO",
    4: "ABRIL",
    5: "MAYO",
    6: "JUNIO",
    7: "JULIO",
    8: "AGOSTO",
    9: "SEPTIEMBRE",
    10: "OCTUBRE",
    11: "NOVIEMBRE",
    12: "DICIEMBRE",
}


const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const day = date.getDate() + 1;
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    return `${formattedDay}/${formattedMonth}/${year}`;

};

const getDateToDay = (): string => {
    const today: Date = new Date();
    const day: number = today.getDate();
    const month: number = today.getMonth() + 1;
    const year: number = today.getFullYear();
    const formattedDay: string = day < 10 ? `0${day}` : `${day}`;
    const formattedMonth: string = month < 10 ? `0${month}` : `${month}`;
    const label = `${year}-${formattedMonth}-${formattedDay}`
    return label;
}

const getDateToDayWithFormat = (): string => {
    const today: Date = new Date();
    const day: number = today.getDate();
    const month: number = today.getMonth() + 1;
    const year: number = today.getFullYear();
    const formattedDay: string = day < 10 ? `0${day}` : `${day}`;
    const formattedMonth: string = month < 10 ? `0${month}` : `${month}`;
    const label = `${year}-${formattedMonth}-${formattedDay}`
    return formatDate(label);
}

const getYearAndMonth = (): string => {
    const value: string = `${Fecha.getDateToDay().split("-")[0]}-${Fecha.getDateToDay().split("-")[1]}`;
    return value;
}

const castMesToLetter = (mes: string | number): string => { // mes = 20
    const data: number = Number(mes.toString().split("-")[1]) ?? 0;
    const anio: string = mes.toString().split("-")[0] ?? "undefined"    
    return mesIndex[data as keyof MesIndex] + " DEL " + anio;

}

const Fecha = {
    formatDate,
    getDateToDay,
    getDateToDayWithFormat,
    getYearAndMonth,
    castMesToLetter
}

export default Fecha;