import colors from "../../config/Colors.config"

const InputGroupText = {
    borderWidth: 2,
    boxShadow: "none",
    backgroundColor: "transparent",
    borderLeftColor: "transparent",
    borderTopColor: "transparent",
    borderRightColor: "transparent",
    borderBottomColor: colors.cafe,
    cursor: "pointer"
}

const Input = {
    borderWidth: 2,
    boxShadow: "none",
    borderLeftColor: "transparent",
    borderTopColor: "transparent",
    borderRightColor: "transparent",
    borderBottomColor: colors.cafe,
    marginTop: -10
}

const ComponentsCSS = { InputGroupText, Input };

export default ComponentsCSS