import Device from "../interfaces/Device.interface";

const getData = (): Device => {
    const isMovil = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const isDesktop = !isMovil
    const device: Device = {
        isDesktop,
        isMovil
    };
    return device;
}


const DeviceConfig = {
    getData
}

export default DeviceConfig;
