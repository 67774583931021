const focus = (Id: string): void => {
    document.getElementById(Id)?.focus();
}

const click = (Id: string): void => {
    document.getElementById(Id)?.click()
}

const Components = {
    focus,
    click
}

export default Components;