import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { FC } from "react";

import LoginUsuario from "./pages/LoginUsuario.page";
import Quejas from "./pages/Quejas.page";

const App: FC = () => {
  return (
    <Router>
      <Routes>        
        <Route path="/" element={<LoginUsuario />} />
        <Route path="/index" element={<Quejas />} />
      </Routes>
    </Router>
  );
}

export default App;
