import ApiConfig from "../config/Api.config";
import Request from "../interfaces/Request.interface";
import Response from "../interfaces/Response.interface";

const urlREDECO: string = `${ApiConfig.urlApiREDECO}/sepomex`
// const url : string =`${ApiConfig.urlApi}/usuario`

const listarEstados = async () => {
    const request: Request = {
        url: `${urlREDECO}/estados`,
        method: "GET",
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};

const listarCodigosPostales = async (estado_id: number) => {
    const request: Request = {
        url: `${urlREDECO}/codigos-postales/?estado_id=${estado_id}`,
        method: "GET",
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};

const listarMunicipios = async (estado_id: number, cp: number) => {
    const request: Request = {
        url: `${urlREDECO}/municipios/?estado_id=${estado_id}&cp=${cp}`,
        method: "GET",
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};

const listarColonias = async (cp: number) => {
    const request: Request = {
        url: `${urlREDECO}/colonias/?cp=${cp}`,
        method: "GET",
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};

const SepomexServices = {
    listarEstados,
    listarCodigosPostales,
    listarMunicipios,
    listarColonias
};

export default SepomexServices;