import Usuario from "../interfaces/Usuario.interface";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const getEstructura = (): Usuario => {
    const usuario: Usuario = {
        IdUsuario: 0,
        username: "",
        password: "",
        token: "",
    };
    return usuario;
};

const getToken = (): string => {
    const usuario: Usuario = cookies.get("usuario") ?? getEstructura();
    const { token } = usuario;
    return token;
}

const UsuarioModel = {
    getEstructura,
    getToken,
}

export default UsuarioModel 