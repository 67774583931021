import { FC, ChangeEvent, useState, useEffect } from "react";
import colors from "../config/Colors.config";
import { Row, Col, FormGroup, Label, Input, InputGroup, InputGroupText, Button } from "reactstrap";
import Login from "../interfaces/Login.interface";
import { Person, Lock, LockOpen } from '@mui/icons-material';
import logo from "../assets/img/logoSinergia.png";
import Components from "../Tools/Components.tool";
import ComponentsCSS from "../assets/css/Components.css";
import Response from "../interfaces/Response.interface";
import UsuarioService from "../services/Usuario.service";
import Usuario from "../interfaces/Usuario.interface";
import { ToastContainer, toast } from "react-toastify";
import md5 from 'md5';

import Cookies from 'universal-cookie';
import Tiempo from "../Tools/Tiempo.tool";


const cookies = new Cookies();

const LoginUsuario: FC = () => {

    const [login, setLogin] = useState<Login>({ username: "", password: "" });
    // const [usuario, setUsuario] = useState<Usuario>(UsuarioModel.getEstructura());
    const [verPassword, setVerPassword] = useState<boolean>(false);

    const { focus } = Components;

    const handleLoginData = (name: string, value: string): void => {
        setLogin((prev: Login) => ({ ...prev, [name]: value }));
    };

    useEffect(() => {
        cargarDatos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const iniciarLogin = async (): Promise<void> => {
        const IdToast = toast.loading("Logueando usuario");
        const username: string = login.username;
        const password: string = md5(login.password);

        const response: Response = await UsuarioService.login(username, password);
        const { data } = response;

        if (data.status === 200) {
            const usuario: Usuario = data.value;
            cookies.set("usuario", usuario, { path: '/' });
            toast.update(IdToast, { render: "Cargando Datos", type: "success", autoClose: 1500, isLoading: false, closeButton: true });
            Tiempo.sleep(1.5).then(() => { window.location.href = "/index" });
        } else {
            toast.update(IdToast, { render: "Acceso Denegado", type: "warning", autoClose: 5000, isLoading: false, closeButton: true });
        }

    };

    const cargarDatos = (): void => {
        focus("username");
    };

    return (
        <section style={{ width: "100%", height: "100vh", backgroundColor: colors.blanco03, display: "flex", justifyContent: "center", alignItems: "center" }} >
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
            <div style={{ width: 520, height: 550, backgroundColor: colors.blanco01, borderRadius: 12 }} >
                <Row style={{ marginTop: 20 }} >
                    <center>
                        <img src={logo} alt="/*-" style={{ width: 275 }} />
                    </center>
                </Row>
                <Row style={{ width: "90%", marginRight: "5%", marginLeft: "5%", marginTop: 20 }} >
                    <Col>
                        <FormGroup>
                            <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                Nombre de Usuario
                            </Label>
                            <InputGroup>
                                <InputGroupText style={ComponentsCSS.InputGroupText}>
                                    <Person style={{ color: colors.cafe }} />
                                </InputGroupText>
                                <Input
                                    id="username"
                                    value={login.username}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        if (value.length > 100) return;
                                        handleLoginData("username", value);
                                    }}
                                    style={ComponentsCSS.Input}
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
                <Row style={{ width: "90%", marginRight: "5%", marginLeft: "5%", marginTop: 20 }} >
                    <Col>
                        <FormGroup>
                            <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                Contraseña
                            </Label>
                            <InputGroup>
                                <InputGroupText
                                    style={ComponentsCSS.InputGroupText}
                                    onClick={() => setVerPassword(!verPassword)}
                                >
                                    {verPassword ? <LockOpen style={{ color: colors.cafe }} /> : <Lock style={{ color: colors.cafe }} />}
                                </InputGroupText>
                                <Input
                                    id="password"
                                    value={login.password}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        if (value.length > 100) return;
                                        handleLoginData("password", value);
                                    }}
                                    style={ComponentsCSS.Input}
                                    type={verPassword ? "text" : "password"}
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
                <Row style={{ width: "90%", marginRight: "5%", marginLeft: "5%", marginTop: 20 }} >
                    <Col>
                        <Button
                            style={{ backgroundColor: colors.blanco01, borderColor: colors.dorado01, color: colors.dorado01, boxShadow: "none", fontWeight: "bold", width: "100%" }}
                            onClick={() => setLogin({ username: "", password: "" })}
                        >
                            Cancelar
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            style={{ backgroundColor: colors.dorado01, borderColor: colors.blanco01, color: colors.blanco01, boxShadow: "none", fontWeight: "bold", width: "100%" }}
                            onClick={() => iniciarLogin()}
                        >
                            Iniciar Sesión
                        </Button>
                    </Col>
                </Row>
            </div>
        </section>
    );
};

export default LoginUsuario;