
const sleep = (seconds: number) => new Promise(
    resolve => setTimeout(resolve, (seconds * 1000))
);

const getCurrentTime = (): string => {
    const date = new Date();
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    return `${hours}:${minutes}:${seconds}`;
};

const getCurrentDate = (): string => {
    const date = new Date();

    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);

    return `${year}-${month}-${day}`;

};

const getNumeroMes = (): number => {
    const date = new Date();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    return +month;
}


const convertTimeTo12HourFormat = (time24: string): string => {
    const [hours, minutes, seconds] = time24.split(':').map(Number);
    const suffix = hours >= 12 ? 'PM' : 'AM';
    const hour12 = hours % 12 || 12;
    return `${hour12.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${suffix}`;
};

const getCurrentTimeFormat12Hour = () => {
    return convertTimeTo12HourFormat(getCurrentTime());
}

const Tiempo = {
    sleep,
    getCurrentTime,
    convertTimeTo12HourFormat,
    getCurrentTimeFormat12Hour,
    getCurrentDate,
    getNumeroMes,
}

export default Tiempo;