import ApiConfig from "../config/Api.config";
import Request from "../interfaces/Request.interface";
import Response from "../interfaces/Response.interface";
import UsuarioModel from "../models/Usuario.model";

const urlREDECO: string = `${ApiConfig.urlApiREDECO}/redeco`
const url: string = `${ApiConfig.urlApi}/queja`

const listar = async (anio: string, mes: string) => {
    const token: string = UsuarioModel.getToken();
    const request: Request = {
        url: `${urlREDECO}/quejas?year=${anio}&month=${mes}`,
        method: "GET",
        headers: {
            Authorization: token
        }
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;

};

const guardar = async (queja: any, quejaDescripcion: any) => {
    const request: Request = {
        url: `${url}/guardar`,
        method: "POST",
        data: { queja, quejaDescripcion }
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;

};

const obtenerPorFolio = async (folio: string) => {
    const request: Request = {
        url: `${url}/${folio}`,
        method: "GET",
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;

};

const enviar = async (queja: any) => {
    const token: string = UsuarioModel.getToken();
    console.log([queja])
    const request: Request = {
        url: `${urlREDECO}/quejas`,
        method: "POST",
        headers: {
            Authorization: token
        },
        data: [queja]

    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};

const eliminar = async (folio: string) => {
    const token: string = UsuarioModel.getToken();
    const request: Request = {
        url: `${urlREDECO}/quejas?quejaFolio=${folio}`,
        method: "DELETE",
        headers: {
            Authorization: token
        }
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;

};

const QuejasServices = {
    listar,
    enviar,
    eliminar,
    obtenerPorFolio,
    guardar,
};

export default QuejasServices;