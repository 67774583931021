import ApiConfig from "../config/Api.config";
import Request from "../interfaces/Request.interface";
import Response from "../interfaces/Response.interface";
import UsuarioModel from "../models/Usuario.model";

const urlREDECO: string = `${ApiConfig.urlApiREDECO}/catalogos`
// const url : string =`${ApiConfig.urlApi}/usuario`

const listarMedioRecepcion = async () => {
    const request: Request = {
        url: `${urlREDECO}/medio-recepcion`,
        method: "GET",
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};

const listarNivelAtencion = async () => {
    const request: Request = {
        url: `${urlREDECO}/niveles-atencion`,
        method: "GET",
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};

const listarProductos = async () => {
    const token: string = UsuarioModel.getToken();
    const request: Request = {
        url: `${urlREDECO}/products-list`,
        method: "GET",
        headers: {
            Authorization: token
        }
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};

const listarProductosCausa = async (productId: string) => {
    const token: string = UsuarioModel.getToken();
    console.log({ url: `${urlREDECO}/causas-list/?product=${productId}` })
    const request: Request = {
        url: `${urlREDECO}/causas-list/?product=${productId}`,
        method: "GET",
        headers: {
            Authorization: token
        }
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
};

const CatalogosServices = {
    listarMedioRecepcion,
    listarNivelAtencion,
    listarProductos,
    listarProductosCausa,
};

export default CatalogosServices;