import { FC, ChangeEvent, useState, useEffect } from "react";
import { Button, Col, DropdownItem, FormGroup, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import ComponentsCSS from "../assets/css/Components.css";
import colors from "../config/Colors.config";
import { Add, Cached, Check, Close, Delete, Help, Logout, Search } from "@mui/icons-material";
import Fecha from "../Tools/Fecha.tool";
import Block from "../components/Block.component";
import QuejasServices from "../services/Quejas.service";
import Queja from "../interfaces/Queja.interface";
import Table, { TableColumn } from 'react-data-table-component';
import { IconButton } from "@mui/material";
import QuejaCorto from "../interfaces/QuejasCorto.interface";
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import Tiempo from "../Tools/Tiempo.tool";
import Cookies from 'universal-cookie';
import UsuarioModel from "../models/Usuario.model";
import QuejaModel from "../models/Queja.model";
import CatalogosServices from "../services/Catalogos.service";
import MedioRecepcionQueja from "../interfaces/MedioQueja.interface";
import NivelAtencionQueja from "../interfaces/NivelAtencionQueja.interface";
import Producto from "../interfaces/Procuto.interface";
import CausaProducto from "../interfaces/CausaProducto.interface";
import Estado from "../interfaces/Estado.interface";
import SepomexServices from "../services/Sepomex.service";
import CodigoPostal from "../interfaces/CodigoPostal.interface";
import Municipio from "../interfaces/Municipio.interface";
import Colonia from "../interfaces/Colonia.interface";
import Numero from "../Tools/Number.tool";
import Response from "../interfaces/Response.interface";
import QuejaDescripcion from "../interfaces/QuejaDescripcion.interface";
import QuejaCompleto from "../interfaces/QuejasCompleto.interface";
import iconGoogleDocs from "../assets/icons/google-docs.png";
import DeviceConfig from "../config/Device.config";

const cookies = new Cookies();

const Quejas: FC = () => {
    const [device, setDevice] = useState(DeviceConfig.getData());
    const [mesQuejas, setMesQuejas] = useState<string>(Fecha.getYearAndMonth());
    // const [quejasLista, setQuejasLista] = useState<QuejaCorto[]>([]);
    const [quejasListaCompleto, setQuejasListaCompleto] = useState<QuejaCompleto[]>([]);
    const [queja, setQueja] = useState<Queja>(QuejaModel.getEstructura());
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [isOpenModalCausaProducto, setIsOpenModalCausaProducto] = useState<boolean>(false);
    const [tiempo, setTiempo] = useState<string>(Tiempo.getCurrentTimeFormat12Hour());
    const [labelCargandoCpEstado, setLabelcargandoCPEstado] = useState<string>("");
    const [isOpenModalError, setIsOpenModalError] = useState<boolean>(false);
    const [errorArray, setErrorArray] = useState<string[]>([]);

    const [quejaDescripcion, setQuejaDescripcion] = useState<QuejaDescripcion>({ quejasMedioDescripcion: "", quejasNivelATDecripcion: "", quejasProductoDescripcion: "", quejasCausaDescripcion: "", quejasEstadosDescripcion: "", quejasMunIdDescripcion: "", quejasColDescripcion: "" });

    /* Catálogos */
    const [mediosRecepcionLista, setMedioRecepcionLista] = useState<MedioRecepcionQueja[]>([]);
    const [nivelAtencionLista, setNivelatencionLista] = useState<NivelAtencionQueja[]>([]);
    const [productosLista, setProductosLista] = useState<Producto[]>([]);
    const [causasProductosLista, setcausasProductosLista] = useState<CausaProducto[]>([]);
    const [labelCausaProducto, setLabelCausaProducto] = useState<string>("");

    /* SEPOMEX */
    const [estadosLista, setEstadosLista] = useState<Estado[]>([]);
    const [codigosPostalesLista, setCodigosPostalesLista] = useState<CodigoPostal[]>([]);
    const [municipiosLista, setMunicipiosLista] = useState<Municipio[]>([]);
    const [coloniasLista, setColoniasLista] = useState<Colonia[]>([]);

    /* Queja Completa Vista */
    const [isOpenModalQueja, setIsOpenModalQueja] = useState<boolean>(false);
    const [quejaCompleta, setQuejaCompleta] = useState<QuejaCompleto>(QuejaModel.quejaCompleta());

    const arrayResolucion = [
        "Selecciona una opción",
        "Totalmente favorable al usuario",
        "Desfavorable al Usuario",
        "Parcialmente favorable al usuario"
    ]

    const arrayPenaliacion = [
        "Selecciona una opción",
        "Cancelación del contrato",
        "Resignación de cartera",
        "Multa",
    ]

    const columnsQuejas: TableColumn<QuejaCompleto>[] = [
        {
            name: <Label style={{ fontWeight: "", color: colors.negro, fontSize: 18 }} > FOLIO </Label>,
            cell: (queja: QuejaCompleto) => <Label style={{ color: colors.negro, fontSize: 16, whiteSpace: 'normal' }} >{queja.quejasFolio}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.negro, fontSize: 18 }} > ESTADO </Label>,
            cell: (queja: QuejaCompleto) => <Label style={{ color: colors.negro, fontSize: 16, whiteSpace: 'normal' }} >{queja.quejasEstadosDescripcion}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.negro, fontSize: 18 }} > MUNICIPIO </Label>,
            cell: (queja: QuejaCompleto) => <Label style={{ color: colors.negro, fontSize: 16, whiteSpace: 'normal' }} >{queja.quejasMunIdDescripcion}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.negro, fontSize: 18 }} > NIVEL DE ATENCIÓN O CONTACTO </Label>,
            cell: (queja: QuejaCompleto) => <Label style={{ color: colors.negro, fontSize: 16, whiteSpace: 'normal' }} >{queja.quejasNivelATDecripcion}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.negro, fontSize: 18 }} > PRODUCTO </Label>,
            cell: (queja: QuejaCompleto) => <Label style={{ color: colors.negro, fontSize: 16, whiteSpace: 'normal' }} >{queja.quejasProductoDescripcion}</Label>,
        },        
        {
            name: <Label style={{ fontWeight: "", color: colors.negro, fontSize: 18 }} > CAUSA </Label>,
            cell: (queja: QuejaCompleto) => <Label style={{ color: colors.negro, fontSize: 16, whiteSpace: 'normal' }} >{queja.quejasCausaDescripcion}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.negro, fontSize: 18 }} >  </Label>,
            cell: (quejaT: QuejaCompleto) =>
                <>
                    <IconButton
                        onClick={() => eliminarQuejas(quejaT.quejasFolio)}
                    >
                        <Delete style={{ color: colors.rojo }} />
                    </IconButton><IconButton
                        onClick={() => {
                            setIsOpenModalQueja(true);
                            setQuejaCompleta(quejaT);
                            console.log(quejaT)
                        }}
                    >
                        <img style={{ width: 23 }} src={iconGoogleDocs} alt="/*-" />
                    </IconButton>

                </>,
        },
    ]

    const columnsCausaProducto: TableColumn<CausaProducto>[] = [
        {
            name: <Label style={{ fontWeight: "", color: colors.negro, fontSize: 18 }} > CAUSAS DESCRIPCIÓN </Label>,
            cell: (causaProducto: CausaProducto) => <Label style={{ color: colors.negro, fontSize: 16, whiteSpace: 'normal', width: "80%" }} >{causaProducto.causa}</Label>,
            width: "90%"
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.negro, fontSize: 18 }} >  </Label>,
            cell: (causaProducto: CausaProducto) =>
                <>
                    <IconButton
                        onClick={() => {
                            handleQuejaData("quejasCausa", causaProducto.causaId);
                            setLabelCausaProducto(causaProducto.causa);
                            handleQuejaDescripcionData("quejasCausaDescripcion", causaProducto.causa);
                            setIsOpenModalCausaProducto(!isOpenModalCausaProducto);
                        }}
                    >
                        <Check style={{ color: colors.dorado01 }} />
                    </IconButton>
                </>,
            width: "10%"
        },
    ]

    const handleQuejaData = (name: string, value: string | number | null): void => {
        setQueja((prev: Queja) => ({ ...prev, [name]: value }));
    };

    const handleQuejaDescripcionData = (name: string, value: string): void => {
        setQuejaDescripcion((prev: QuejaDescripcion) => ({ ...prev, [name]: value }));
    };

    const listarQuejas = async (anio: string, mes: string): Promise<void> => {
        const response: any = await QuejasServices.listar(anio, mes);
        const quejasLista: QuejaCorto[] = response.data.quejas ?? [];
        console.log(quejasLista)
        let quejasListaCompleto: QuejaCompleto[] = [];
        for (let index = 0; index < quejasLista.length; index++) {
            const quejaCorto: QuejaCorto = quejasLista[index];
            let response: Response = await QuejasServices.obtenerPorFolio(quejaCorto.folio);
            if (response.data) {
                let quejaCompleto: QuejaCompleto = response.data.value;
                quejasListaCompleto.push(quejaCompleto);
            }
        }
        setQuejasListaCompleto(quejasListaCompleto);
        // setQuejasLista(quejasLista);
    };

    const listarMediosRecepcionQuejas = async (): Promise<void> => {
        const response: any = await CatalogosServices.listarMedioRecepcion();
        const mediosRecepcionLista: MedioRecepcionQueja[] = response.data.medio ?? [];
        setMedioRecepcionLista(mediosRecepcionLista);
    };

    const listarNivelAtencionQuejas = async (): Promise<void> => {
        const response: any = await CatalogosServices.listarNivelAtencion();
        const nivelAtencionLista: NivelAtencionQueja[] = response.data.nivelesDeAtencion ?? [];
        setNivelatencionLista(nivelAtencionLista);
    };

    const listarProductosQuejas = async (): Promise<void> => {
        const response: any = await CatalogosServices.listarProductos();
        const productosLista: Producto[] = response.data.products ?? [];
        setProductosLista(productosLista);
    };

    const listarProductosCausaQuejas = async (productId: string): Promise<void> => {
        const response: any = await CatalogosServices.listarProductosCausa(productId);
        const causasProductosLista: CausaProducto[] = response.data.causas ?? [];
        setcausasProductosLista(causasProductosLista);
    };

    const listarEstados = async (): Promise<void> => {
        const response: any = await SepomexServices.listarEstados();
        const estadosLista: Estado[] = response.data.estados ?? [];
        setEstadosLista(estadosLista);
    };

    const listarCodigosPostales = async (estado_id: number): Promise<void> => {
        const response: any = await SepomexServices.listarCodigosPostales(estado_id);
        let codigosPostalesLista: CodigoPostal[] = response.data.codigos_postales ?? [];
        codigosPostalesLista = codigosPostalesLista.filter((value, index, self) =>
            index === self.findIndex((t) =>
                Object.keys(t).every(key => t[key as keyof CodigoPostal] === value[key as keyof CodigoPostal])
            )
        ).sort((a, b) => a.codigo_sepomex - b.codigo_sepomex);
        setCodigosPostalesLista(codigosPostalesLista);
    };

    const listarMunicipio = async (estado_id: number, cp: number): Promise<void> => {
        const response: any = await SepomexServices.listarMunicipios(estado_id, cp);
        const municipiosLista: Municipio[] = response.data.municipios ?? [];
        setMunicipiosLista(municipiosLista);
    };

    const listarColonia = async (cp: number): Promise<void> => {
        const response: any = await SepomexServices.listarColonias(cp);
        let coloniasLista: Colonia[] = response.data.colonias ?? [];
        coloniasLista = coloniasLista.sort((a, b) =>
            a.colonia.localeCompare(b.colonia)
        );
        setColoniasLista(coloniasLista);
    };

    const eliminarQuejas = async (folio: string): Promise<void> => {

        Swal.fire({
            icon: "question",
            title: "Eliminar Postulante",
            text: `¿Estas seguro de eliminar esta queja con folio: ${folio}?`,
            showConfirmButton: true,
            confirmButtonColor: colors.rojo,
            showCancelButton: true,
            cancelButtonText: "No, Eliminar",
            confirmButtonText: "Si, Eliminar",
            timerProgressBar: true,
            reverseButtons: true,
        }).then(async (response) => {
            if (response.isConfirmed) {
                await QuejasServices.eliminar(folio);
                toast.success("Queja eliminada");
                const mes: string = mesQuejas.split("-")[1];
                const anio: string = mesQuejas.split("-")[0];
                listarQuejas(anio, mes);
            }
        });
    };

    const cargarDatos = async (): Promise<void> => {

        setDevice(DeviceConfig.getData());

        if (UsuarioModel.getToken().length > 0) {
            const mes: string = mesQuejas.split("-")[1];
            const anio: string = mesQuejas.split("-")[0];
            await Tiempo.sleep(1.5).then(() => listarQuejas(anio, mes));
        } else {
            document.body.innerHTML = "<body></body>";
            Swal.fire({
                icon: "warning",
                title: "Acceso denegado",
                text: `Tienes que iniciar sesión para poder usar el sistema`,
                showConfirmButton: true,
                confirmButtonColor: colors.dorado01,
                confirmButtonText: "Ir al login",
                timerProgressBar: true,
                reverseButtons: true,
                timer: 10000
            }).then(async (response) => {
                if (response.isConfirmed) window.location.href = "/"
            });
            Tiempo.sleep(10).then(() => window.location.href = "/");
        }

    }

    const enviarQuejas = async (): Promise<void> => {
        const data: any = await QuejaModel.castQueja(queja);
        let response: any = await QuejasServices.enviar(data)
        if (response.error === true) {
            const ppp: string[] = response.data.response.data.errors[queja.quejasFolio] ?? [];
            if (ppp.length > 0) { setErrorArray(ppp); setIsOpenModalError(true) }
        } else {
            toast.success("Datos enviados");
            const ttt: QuejaDescripcion = quejaDescripcion;
            const response01: Response = await QuejasServices.guardar(data, ttt);
            const responseData = response01.data;
            if (responseData.error) {
                toast.error("ERROR");
            } else {
                const mes: string = mesQuejas.split("-")[1];
                const anio: string = mesQuejas.split("-")[0];
                listarQuejas(anio, mes);
            }
        }

    }

    useEffect(() => {
        cargarDatos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <section style={{ width: "100%", height: "100vh" }} >
                <ToastContainer limit={3} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
                <Block height={70} />
                <Row style={{ marginRight: device.isDesktop ? 50 : 5, marginLeft: device.isDesktop ? 50 : 5 }} >
                    <Col style={{ textAlign: "right" }} >
                        <Logout style={{ marginRight: 10, color: colors.dorado01 }} />
                        <Label
                            style={{ color: colors.dorado01, textDecorationLine: "underline", fontSize: 18, cursor: "pointer" }}
                            onClick={() => {
                                Swal.fire({
                                    icon: "question",
                                    title: "Salir del Sistema",
                                    text: `¿Estas seguro de que quieres salir del sistema?`,
                                    showConfirmButton: true,
                                    confirmButtonColor: colors.dorado01,
                                    showCancelButton: true,
                                    cancelButtonText: "No",
                                    confirmButtonText: "Si",
                                    timerProgressBar: true,
                                    reverseButtons: true,
                                    timer: 10000
                                }).then(async (response) => {
                                    if (response.isConfirmed) {
                                        cookies.remove("usuario");
                                        Tiempo.sleep(0.5).then(() => window.location.href = "/");
                                    }
                                });
                            }}
                        >
                            Salir del Sistema
                        </Label>
                    </Col>
                </Row>
                <Row style={{ marginRight: device.isDesktop ? 50 : 5, marginLeft: device.isDesktop ? 50 : 5 }} >
                    <Label style={{ fontWeight: "bold", fontSize: 24, color: colors.dorado01 }} >
                        Lista de quejas
                    </Label>
                </Row>
                <Row style={{ marginRight: device.isDesktop ? 50 : 5, marginLeft: device.isDesktop ? 50 : 5, marginTop: 10 }} >
                    <Col>
                        <FormGroup>
                            <Label style={{ color: colors.dorado01 }} >
                                Mes
                            </Label>
                            <InputGroup>
                                <Input
                                    id="mesQuejas"
                                    value={mesQuejas}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        setMesQuejas(value);
                                        const mes: string = value.split("-")[1];
                                        const anio: string = value.split("-")[0];
                                        Tiempo.sleep(0.7).then(() => listarQuejas(anio, mes));
                                    }}
                                    style={ComponentsCSS.Input}
                                    type="month"
                                />
                                <InputGroupText
                                    style={ComponentsCSS.InputGroupText}
                                    onClick={() => {
                                        const mes: string = mesQuejas.split("-")[1];
                                        const anio: string = mesQuejas.split("-")[0];
                                        listarQuejas(anio, mes);
                                    }}
                                >
                                    <Search style={{ color: colors.dorado01 }} />
                                </InputGroupText>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col style={{ textAlign: "right" }} >
                        <IconButton
                            style={{ marginTop: 30, marginRight: 5 }}
                            onClick={() => {
                                const mes: string = mesQuejas.split("-")[1];
                                const anio: string = mesQuejas.split("-")[0];
                                listarQuejas(anio, mes);
                                setTiempo(Tiempo.getCurrentTimeFormat12Hour());
                            }}
                        >
                            <Cached style={{ color: colors.dorado01 }} />
                        </IconButton>
                        <IconButton style={{ marginTop: 30 }} >
                            <Add
                                style={{ color: colors.dorado01 }}
                                onClick={() => setIsOpenModal(!isOpenModal)}
                            />
                        </IconButton>
                    </Col>
                </Row>
                <Row style={{ marginRight: device.isDesktop ? 50 : 5, marginLeft: device.isDesktop ? 50 : 5, marginTop: 10 }} >
                    <Col>
                        <Label>
                            Ultima actualización a las {tiempo}
                        </Label>
                    </Col>
                </Row>
                <Row style={{ marginRight: device.isDesktop ? 50 : 5, marginLeft: device.isDesktop ? 50 : 5, marginTop: 10 }} >
                    <Table
                        columns={columnsQuejas}
                        data={quejasListaCompleto}
                        noDataComponent={<Label style={{ fontWeight: "bold", fontSize: 26, color: colors.gris, marginTop: 50, textAlign: "center" }} >TABLA VACÍA</Label>}
                    />
                </Row>
            </section>
            <Modal
                isOpen={isOpenModal}
                style={{ maxWidth: device.isDesktop ? "90%" : "100%", fontFamily: '"Fira Sans", sans-serif' }}
                onOpened={() => {
                    listarMediosRecepcionQuejas();
                    listarNivelAtencionQuejas();
                    listarProductosQuejas();
                    listarEstados();
                    setLabelCausaProducto("");
                    setcausasProductosLista([]);
                }}
                onClosed={() => { setQueja(QuejaModel.getEstructura()); setLabelCausaProducto(""); setcausasProductosLista([]); }}
            >
                <ModalHeader
                    close={
                        <IconButton
                            onClick={() => setIsOpenModal(!isOpenModal)}
                            style={{ backgroundColor: colors.blanco01, color: colors.dorado01 }}
                        >
                            <Close />
                        </IconButton>
                    }
                    style={{
                        backgroundColor: colors.dorado01
                    }}
                >
                    <Label style={{ color: colors.blanco01, fontSize: 24 }} >
                        DATOS PARA ENVIAR
                    </Label>
                </ModalHeader>
                <ModalBody style={{ minHeight: "65vh", maxHeight: "65vh", height: "65vh", overflowY: "auto" }} >
                    <Row style={{ marginRight: 0, marginLeft: 0 }} >
                        <Col style={{ minWidth: device.isDesktop ? "40%" : "100%", width: device.isDesktop ? "40%" : "100%", maxWidth: device.isDesktop ? "40%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Denominación o razón social
                                </Label>
                                <Input
                                    id="queja.quejasDenominacion"
                                    value={queja.quejasDenominacion}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        handleQuejaData("quejasDenominacion", value);
                                    }}
                                    style={{ ...ComponentsCSS.Input, cursor: "not-allowed" }}
                                    disabled
                                    title={"Este dato no se puede cambiar, solo es informativo"}
                                />
                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: "40%", width: "40%", maxWidth: "40%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Sector
                                </Label>
                                <Input
                                    id="queja.quejasSector"
                                    value={queja.quejasSector}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        handleQuejaData("quejasSector", value);
                                    }}
                                    style={{ ...ComponentsCSS.Input, cursor: "not-allowed" }}
                                    disabled
                                    title={"Este dato no se puede cambiar, solo es informativo"}
                                />
                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Mes a informar
                                </Label>
                                <Input
                                    id="queja.quejasNoMes"
                                    value={queja.quejasNoMes}
                                    type={"month"}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        handleQuejaData("quejasNoMes", value);
                                    }}
                                    style={ComponentsCSS.Input}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0 }} >
                        <Col style={{ minWidth: device.isDesktop ? "15%" : "100%", width: device.isDesktop ? "15%" : "100%", maxWidth: device.isDesktop ? "15%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Número de quejas
                                </Label>
                                <Input
                                    id="queja.quejasNum"
                                    value={queja.quejasNum}
                                    type={"text"}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        if (value.charAt(0) === "0") value = value.charAt(1)
                                        if (value.length > 1) return
                                        value = Numero.singleNumbers(value);
                                        handleQuejaData("quejasNum", +value);
                                    }}
                                    style={ComponentsCSS.Input}
                                />
                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "25%" : "100%", width: "25%", maxWidth: "25%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    FOLIO
                                </Label>
                                <Input
                                    id="queja.quejasFolio"
                                    value={queja.quejasFolio}
                                    type={"text"}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        handleQuejaData("quejasFolio", value);
                                    }}
                                    style={ComponentsCSS.Input}
                                />
                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Fecha de la queja
                                </Label>
                                <Input
                                    id="queja.quejasFecRecepcion"
                                    value={queja.quejasFecRecepcion}
                                    type={"date"}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        handleQuejaData("quejasFecRecepcion", value);
                                    }}
                                    style={ComponentsCSS.Input}
                                />
                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Medio de recepción o canal
                                </Label>
                                <Input
                                    id="queja.quejasMedio"
                                    value={queja.quejasMedio}
                                    type={"select"}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        handleQuejaData("quejasMedio", value);
                                        const medio: string | undefined = mediosRecepcionLista.find(medio => +medio.medioId === +value)?.medioDsc;
                                        console.log(medio)
                                        if (medio) handleQuejaDescripcionData("quejasMedioDescripcion", medio.replace("'", ""));
                                    }}
                                    style={ComponentsCSS.Input}
                                >
                                    <option value="0">Selecciona una opción</option>
                                    {
                                        mediosRecepcionLista.map((item: MedioRecepcionQueja) => {
                                            const { medioId, medioDsc } = item;
                                            return (
                                                <option value={medioId}> {medioDsc} </option>
                                            );
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Nivel de atención o contacto
                                </Label>
                                <Input
                                    id="queja.quejasNivelAT"
                                    value={queja.quejasNivelAT}
                                    type={"select"}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        handleQuejaData("quejasNivelAT", value);
                                        const nivelAtencion: string | undefined = nivelAtencionLista.find(nivelAtencion => +nivelAtencion.nivelDeAtencionId === +value)?.nivelDeAtencionDsc;
                                        if (nivelAtencion) handleQuejaDescripcionData("quejasNivelATDecripcion", nivelAtencion);
                                    }}
                                    style={ComponentsCSS.Input}
                                >
                                    <option value="0">Selecciona una opción</option>
                                    {
                                        nivelAtencionLista.map((item: NivelAtencionQueja) => {
                                            const { nivelDeAtencionId, nivelDeAtencionDsc } = item;
                                            return (
                                                <option value={nivelDeAtencionId}> {nivelDeAtencionDsc} </option>
                                            );
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>

                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0 }} >
                        <Col style={{ minWidth: device.isDesktop ? "40%" : "100%", width: device.isDesktop ? "40%" : "100%", maxWidth: device.isDesktop ? "40%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Producto y/o Servicio
                                </Label>
                                <Input
                                    id="queja.quejasProducto"
                                    value={queja.quejasProducto}
                                    type={"select"}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        handleQuejaData("quejasProducto", value);
                                        listarProductosCausaQuejas(value);
                                        const producto: string | undefined = productosLista.find(producto => +producto.productId === +value)?.product;
                                        if (producto) handleQuejaDescripcionData("quejasProductoDescripcion", producto);
                                    }}
                                    style={ComponentsCSS.Input}
                                >
                                    <option value="NAN">Selecciona una opción</option>
                                    {
                                        productosLista.map((item: Producto) => {
                                            const { productId, product, institucion } = item;
                                            return (
                                                <option value={productId} title={institucion}> {product} </option>
                                            );
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0 }} >

                        <Col style={{ minWidth: "100%", width: "100%", maxWidth: "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Causa de la queja
                                </Label>
                                <Input
                                    // id="queja.quejasCausa"
                                    value={labelCausaProducto}
                                    type={"textarea"}
                                    // onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                    //     handleQuejaData("quejasCausa", value);
                                    // }}
                                    onClick={() => setIsOpenModalCausaProducto(!isOpenModalCausaProducto)}
                                    style={ComponentsCSS.Input}
                                >
                                    {/* <option value="0">Selecciona una opción</option>
                                    {
                                        causasProductosLista.map((item: CausaProducto) => {
                                            const { causaId, causa, institucion } = item;
                                            return (
                                                <option value={causaId} title={institucion}> {causa} </option>
                                            );
                                        })
                                    } */}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0 }} >
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    PORI
                                </Label>
                                <Input
                                    id="queja.quejasPORI"
                                    value={queja.quejasPORI}
                                    type={"select"}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        handleQuejaData("quejasPORI", value);
                                    }}
                                    style={ComponentsCSS.Input}
                                >
                                    <option value="NAN">Selecciona una opción</option>
                                    <option value="SI">SI</option>
                                    <option value="NO">NO</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Estatus
                                </Label>
                                <Input
                                    id="queja.quejasEstatus"
                                    value={queja.quejasEstatus}
                                    type={"select"}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        handleQuejaData("quejasEstatus", +value);
                                        if (+value === 2) {
                                            handleQuejaData("quejasFecResolucion", Fecha.getDateToDay());
                                            handleQuejaData("quejasFecNotificacion", Fecha.getDateToDay());
                                        } else {
                                            if (+value === 1) {
                                                handleQuejaData("quejasFecResolucion", null);
                                                handleQuejaData("quejasFecNotificacion", null);
                                                handleQuejaData("quejasRespuesta", null);
                                            }
                                        }
                                    }}
                                    style={ComponentsCSS.Input}
                                >
                                    <option value="0">Selecciona una opción</option>
                                    <option value={1} title={"Queja que no ha sido resuelta al Cliente por la Institución Financiera, dentro del mes a reportar."} >Pendiente</option>
                                    <option value={2} title={"Queja resuelta al Cliente por la Institución Financiera, dentro del mes a reportar."}>Concluido</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Estado
                                </Label>
                                <Input
                                    id="queja.quejasEstados"
                                    value={queja.quejasEstados}
                                    type={"select"}
                                    onChange={async ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        await setLabelcargandoCPEstado("Cargando codigos postales...");
                                        await handleQuejaData("quejasEstados", +value);
                                        await listarCodigosPostales(+value)
                                        await setLabelcargandoCPEstado("Codigos postales cargados");

                                        const estado: string | undefined = estadosLista.find(estado => +estado.claveEdo === +value)?.estado
                                        if (estado) handleQuejaDescripcionData("quejasEstadosDescripcion", estado);
                                        await Tiempo.sleep(3).then(() => setLabelcargandoCPEstado(""));
                                    }}
                                    style={ComponentsCSS.Input}
                                >
                                    <option value={0}>Selecciona una opción</option>
                                    {
                                        estadosLista.map((item: Estado) => {
                                            const { claveEdo, estado } = item;
                                            return (
                                                <option value={claveEdo}>{estado}</option>
                                            );
                                        })
                                    }
                                </Input>
                                <span >{labelCargandoCpEstado}</span>
                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Código Postal
                                </Label>
                                <Input
                                    id="queja.quejasCP"
                                    value={queja.quejasCP}
                                    type={"select"}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        handleQuejaData("quejasCP", value);
                                        listarMunicipio(queja.quejasEstados, +value);
                                        listarColonia(+value)
                                    }}
                                    style={ComponentsCSS.Input}
                                >
                                    <option value={0}>Selecciona una opción</option>
                                    {
                                        codigosPostalesLista.map((item: CodigoPostal) => {
                                            const { estado, codigo_sepomex } = item;
                                            return (
                                                <option value={codigo_sepomex} title={estado}>{codigo_sepomex}</option>
                                            );
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Municipio o Alcaldía.
                                </Label>
                                <Input
                                    id="queja.quejasMunId"
                                    value={queja.quejasMunId}
                                    type={"select"}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        handleQuejaData("quejasMunId", value);
                                        const municipio: string | undefined = municipiosLista.find(municipio => +municipio.municipioId === +value)?.municipio;
                                        if (municipio) handleQuejaDescripcionData("quejasMunIdDescripcion", municipio);
                                    }}
                                    style={ComponentsCSS.Input}
                                >
                                    <option value={0}>Selecciona una opción</option>
                                    {
                                        municipiosLista.map((item: Municipio) => {
                                            const { municipio, municipioId } = item;
                                            return (
                                                <option value={municipioId} >{municipio}</option>
                                            );
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0 }} >
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Colonia
                                </Label>
                                <Input
                                    id="queja.quejasColId"
                                    value={queja.quejasColId}
                                    type={"select"}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        handleQuejaData("quejasColId", value);
                                        const colinia: string | undefined = coloniasLista.find(calonia => +calonia.coloniaId === +value)?.colonia;
                                        if (colinia) handleQuejaDescripcionData("quejasColDescripcion", colinia);
                                    }}
                                    style={ComponentsCSS.Input}
                                >
                                    <option value={0}>Selecciona una opción</option>
                                    {
                                        coloniasLista.map((item: Colonia) => {
                                            const { colonia, coloniaId, estado } = item;
                                            return (
                                                <option value={coloniaId} title={estado} >{colonia}</option>
                                            );
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Tipo de Persona
                                </Label>
                                <Input
                                    id="queja.quejasTipoPersona"
                                    value={queja.quejasTipoPersona}
                                    type={"select"}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        handleQuejaData("quejasTipoPersona", +value);
                                        if (+value === 2) {
                                            handleQuejaData("quejasSexo", null);
                                            handleQuejaData("quejasEdad", null);
                                        }
                                    }}
                                    style={ComponentsCSS.Input}
                                >
                                    <option value={0}>Selecciona una opción</option>
                                    <option value={1}>Persona Física</option>
                                    <option value={2}>Persona Moral</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Sexo
                                </Label>
                                {
                                    queja.quejasTipoPersona === 2 ?
                                        <Label>
                                            <i>Esta dato ya no se toma en cuenta</i>
                                        </Label>
                                        :
                                        <Input
                                            id="queja.quejasSexo"
                                            value={queja.quejasSexo ? queja.quejasSexo : "NAN"}
                                            type={"select"}
                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                handleQuejaData("quejasSexo", value);
                                            }}
                                            style={ComponentsCSS.Input}
                                        >
                                            <option value={"NAN"}>Selecciona una opción</option>
                                            <option value={"H"}>HOMBRE</option>
                                            <option value={"M"}>MUJER</option>
                                        </Input>
                                }

                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} title={"Solo para persona física (En caso de que lo tengan)"}>
                                    Edad (Personas Físicas) <Help style={{ height: 20, width: 20, marginLeft: 5, marginTop: -3, cursor: "pointer" }} />
                                </Label>
                                {
                                    queja.quejasTipoPersona === 2 ?
                                        <Label>
                                            <i>Esta dato ya no se toma en cuenta</i>
                                        </Label>
                                        :
                                        <Input
                                            id="queja.quejasEdad"
                                            value={queja.quejasEdad === null ? 0 : queja.quejasEdad}
                                            type={"text"}
                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                if (value.length > 2) return;
                                                value = Numero.singleNumbers(value)
                                                handleQuejaData("quejasEdad", +value);
                                            }}
                                            style={ComponentsCSS.Input}
                                        />
                                }

                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} title={"Solo para persona física (En caso de que lo tengan)"}>
                                    Fecha de resolución
                                </Label>
                                {
                                    queja.quejasEstatus === 1 ?
                                        < Label >
                                            <i>Esta dato ya no se toma en cuenta</i>
                                        </Label>
                                        :
                                        <Input
                                            id="queja.quejasFecResolucion"
                                            value={queja.quejasFecResolucion}
                                            type={"date"}
                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                handleQuejaData("quejasFecResolucion", value);
                                            }}
                                            style={ComponentsCSS.Input}
                                        />
                                }

                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0 }} >

                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} title={"Solo para persona física (En caso de que lo tengan)"}>
                                    Fecha que se notifico al cliente
                                </Label>
                                {
                                    queja.quejasEstatus === 1 ?
                                        <Label>
                                            <i>Esta dato ya no se toma en cuenta</i>
                                        </Label>
                                        :
                                        <Input
                                            id="queja.quejasFecNotificacion"
                                            value={queja.quejasFecNotificacion}
                                            type={"date"}
                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                handleQuejaData("quejasFecNotificacion", value);
                                            }}
                                            style={ComponentsCSS.Input}
                                        />
                                }
                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} title={"Solo para persona física (En caso de que lo tengan)"}>
                                    Sentido de la resolución
                                </Label>
                                {
                                    queja.quejasEstatus === 1 ?
                                        <Label>
                                            <i>Esta dato ya no se toma en cuenta</i>
                                        </Label>
                                        :
                                        <Input
                                            id="queja.quejasRespuesta"
                                            value={queja.quejasRespuesta}
                                            type={"select"}
                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                handleQuejaData("quejasRespuesta", +value);
                                            }}
                                            style={ComponentsCSS.Input}
                                        >
                                            <option value={0}>Selecciona una opción</option>
                                            <option value={1}>Totalmente favorable al usuario</option>
                                            <option value={2}>Desfavorable al Usuario</option>
                                            <option value={3}>Parcialmente favorable al usuario</option>
                                        </Input>
                                }

                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} title={"Solo para persona física (En caso de que lo tengan)"}>
                                    Número de penalización
                                </Label>
                                <Input
                                    id="queja.quejasNumPenal"
                                    value={queja.quejasNumPenal}
                                    type={"text"}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        if (value.length > 4) return;
                                        value = Numero.singleNumbers(value);
                                        handleQuejaData("quejasNumPenal", +value);
                                    }}
                                    style={ComponentsCSS.Input}
                                />
                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "40%" : "100%", width: device.isDesktop ? "40%" : "100%", maxWidth: device.isDesktop ? "40%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} title={"Solo para persona física (En caso de que lo tengan)"}>
                                    Tipo de penalización
                                </Label>
                                <Input
                                    id="queja.quejasPenalizacion"
                                    value={queja.quejasPenalizacion}
                                    type={"select"}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        value = Numero.singleNumbers(value)
                                        handleQuejaData("quejasPenalizacion", +value);
                                    }}
                                    style={ComponentsCSS.Input}
                                >
                                    <option value={0}>Selecciona una opción</option>
                                    <option title={"Contractuales"} value={1}>Cancelación del contrato</option>
                                    <option title={"Contractuales"} value={2}>Resignación de cartera</option>
                                    <option title={"Económicas"} value={3}>Multa</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        style={{ backgroundColor: colors.blanco01, borderColor: colors.dorado01, color: colors.dorado01, fontWeight: "bold", boxShadow: "none" }}
                        onClick={() => {
                            enviarQuejas();
                        }}
                    >
                        Enviar
                    </Button>
                </ModalFooter>
            </Modal >
            <Modal
                isOpen={isOpenModalCausaProducto}
                style={{ maxWidth: device.isDesktop ? "90%" : "100%", fontFamily: '"Fira Sans", sans-serif' }}
            >
                <ModalHeader
                    close={
                        <IconButton
                            onClick={() => setIsOpenModalCausaProducto(!isOpenModalCausaProducto)}
                            style={{ backgroundColor: colors.blanco01, color: colors.dorado01 }}
                        >
                            <Close />
                        </IconButton>
                    }
                    style={{
                        backgroundColor: colors.dorado01
                    }}
                >
                    <Label style={{ color: colors.blanco01, fontSize: 24 }} >
                        CAUSAS
                    </Label>
                </ModalHeader>
                <ModalBody style={{ minHeight: "65vh", maxHeight: "65vh", height: "65vh", overflowY: "auto" }} >
                    <Row style={{ marginRight: 0, marginLeft: 0 }} >
                        <Table
                            columns={columnsCausaProducto}
                            data={causasProductosLista}
                            noDataComponent={<Label style={{ fontWeight: "bold", fontSize: 26, color: colors.gris, marginTop: 50, textAlign: "center" }} >TABLA VACÍA <br /><span style={{ fontSize: 18 }} >No has seleccionado un producto o servicio</span> </Label>}
                        />
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        style={{ backgroundColor: colors.blanco01, borderColor: colors.dorado01, color: colors.dorado01, fontWeight: "bold", boxShadow: "none" }}
                        onClick={() => setIsOpenModalCausaProducto(!isOpenModalCausaProducto)}
                    >
                        Cancelar
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={isOpenModalError}
                size="lg"
            >
                <ModalHeader
                    close={
                        <IconButton
                            onClick={() => setIsOpenModalError(!isOpenModalError)}
                            style={{ backgroundColor: colors.blanco01, color: colors.dorado01 }}
                        >
                            <Close />
                        </IconButton>
                    }
                    style={{
                        backgroundColor: colors.dorado01
                    }}
                >
                    <Label style={{ color: colors.blanco01, fontSize: 24 }} >
                        Errores
                    </Label>
                </ModalHeader>
                <ModalBody>

                    {
                        errorArray.map((item: string) => {
                            return (
                                <Row>
                                    <Col>
                                        <Label>
                                            {item}
                                        </Label>
                                        <DropdownItem divider />
                                    </Col>
                                </Row>
                            )
                        })
                    }
                </ModalBody>
            </Modal >
            {/*  */}
            <Modal
                isOpen={isOpenModalQueja}
                style={{ maxWidth: device.isDesktop ? "90%":"100%", fontFamily: '"Fira Sans", sans-serif' }}
                onOpened={() => { }}
                onClosed={() => { setQuejaCompleta(QuejaModel.quejaCompleta()); }}
            >
                <ModalHeader
                    close={
                        <IconButton
                            onClick={() => setIsOpenModalQueja(false)}
                            style={{ backgroundColor: colors.blanco01, color: colors.dorado01 }}
                        >
                            <Close />
                        </IconButton>
                    }
                    style={{
                        backgroundColor: colors.dorado01
                    }}
                >
                    <Label style={{ color: colors.blanco01, fontSize: 24 }} >
                        DATOS DE LA QUEJA
                    </Label>
                </ModalHeader>
                <ModalBody style={{ minHeight: "65vh", maxHeight: "65vh", height: "65vh", overflowY: "auto" }} >
                    <Row>
                        <Col style={{ minWidth: device.isDesktop ? "40%" : "100%", width: device.isDesktop ? "40%" : "100%", maxWidth: device.isDesktop ? "40%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Denominación o razón social
                                </Label>
                                <br />
                                <Label
                                    style={{ backgroundColor: colors.blanco01, borderColor: colors.blanco01 }}
                                >
                                    {quejaCompleta.quejasDenominacion}
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "40%" : "100%", width: device.isDesktop ? "40%" : "100%", maxWidth: device.isDesktop ? "40%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Sector
                                </Label>
                                <br />
                                <Label
                                    style={{ backgroundColor: colors.blanco01, borderColor: colors.blanco01 }}
                                >
                                    {quejaCompleta.quejasSector}
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Mes que se informo
                                </Label>
                                <br />
                                <Label
                                    style={{ backgroundColor: colors.blanco01, borderColor: colors.blanco01 }}
                                >
                                    {Fecha.castMesToLetter(quejaCompleta.quejasFecRecepcion)}
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ minWidth: device.isDesktop ? "15%" : "100%", width: device.isDesktop ? "15%" : "100%", maxWidth: device.isDesktop ? "15%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Numero de quejas
                                </Label>
                                <br />
                                <Label
                                    style={{ backgroundColor: colors.blanco01, borderColor: colors.blanco01 }}
                                >
                                    {quejaCompleta.quejasNum}
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "25%" : "100%", width: device.isDesktop ? "25%" : "100%", maxWidth: device.isDesktop ? "25%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    FOLIO
                                </Label>
                                <br />
                                <Label
                                    style={{ backgroundColor: colors.blanco01, borderColor: colors.blanco01 }}
                                >
                                    {quejaCompleta.quejasFolio}
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "%100", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "%100" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Fecha de la queja
                                </Label>
                                <br />
                                <Label
                                    style={{ backgroundColor: colors.blanco01, borderColor: colors.blanco01 }}
                                >
                                    {Fecha.formatDate(quejaCompleta.quejasFecRecepcion)}
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Medio de recepción o canal
                                </Label>
                                <br />
                                <Label
                                    style={{ backgroundColor: colors.blanco01, borderColor: colors.blanco01 }}
                                >
                                    {quejaCompleta.quejasMedioDescripcion}
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Nivel de atención o contacto
                                </Label>
                                <br />
                                <Label
                                    style={{ backgroundColor: colors.blanco01, borderColor: colors.blanco01 }}
                                >
                                    {quejaCompleta.quejasNivelATDecripcion}
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ minWidth: device.isDesktop ? "40%" : "100%", width: device.isDesktop ? "40%" : "100%", maxWidth: device.isDesktop ? "40%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Producto y/o Servicio
                                </Label>
                                <br />
                                <Label
                                    style={{ backgroundColor: colors.blanco01, borderColor: colors.blanco01 }}
                                >
                                    {quejaCompleta.quejasProductoDescripcion}
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ minWidth: "100%", width: "100%", maxWidth: "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Producto y/o Servicio
                                </Label>
                                <br />
                                <Label
                                    style={{ backgroundColor: colors.blanco01, borderColor: colors.blanco01 }}
                                >
                                    {quejaCompleta.quejasCausaDescripcion}
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    PORI
                                </Label>
                                <br />
                                <Label
                                    style={{ backgroundColor: colors.blanco01, borderColor: colors.blanco01 }}
                                >
                                    {quejaCompleta.quejasPORI}
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Estatus
                                </Label>
                                <br />
                                <Label
                                    style={{ backgroundColor: colors.blanco01, borderColor: colors.blanco01 }}
                                >
                                    {quejaCompleta.quejasEstatus === 1 ? "Pendiente" : (quejaCompleta.quejasEstatus === 2 ? "Concluido" : "/*-")}
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Estado
                                </Label>
                                <br />
                                <Label
                                    style={{ backgroundColor: colors.blanco01, borderColor: colors.blanco01 }}
                                >
                                    {quejaCompleta.quejasEstadosDescripcion}
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Codigo Postal
                                </Label>
                                <br />
                                <Label
                                    style={{ backgroundColor: colors.blanco01, borderColor: colors.blanco01 }}
                                >
                                    {quejaCompleta.quejasCP}
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Municipio o Alcaldía
                                </Label>
                                <br />
                                <Label
                                    style={{ backgroundColor: colors.blanco01, borderColor: colors.blanco01 }}
                                >
                                    {quejaCompleta.quejasMunIdDescripcion}
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Colonia
                                </Label>
                                <br />
                                <Label
                                    style={{ backgroundColor: colors.blanco01, borderColor: colors.blanco01 }}
                                >
                                    {quejaCompleta.quejasColDescripcion}
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Tipo de Persona
                                </Label>
                                <br />
                                <Label
                                    style={{ backgroundColor: colors.blanco01, borderColor: colors.blanco01 }}
                                >
                                    {quejaCompleta.quejasTipoPersona === 1 ? "Física" : (quejaCompleta.quejasTipoPersona === 2 ? "Moral" : "/*-")}
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Sexo
                                </Label>
                                <br />
                                <Label
                                    style={{ backgroundColor: colors.blanco01, borderColor: colors.blanco01 }}
                                >
                                    {quejaCompleta.quejasSexo === "H" ? "Hombre" : (quejaCompleta.quejasSexo === "M" ? "Hombre" : "Mujer")}
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Edad (Persona Física)
                                </Label>
                                <br />
                                <Label
                                    style={{ backgroundColor: colors.blanco01, borderColor: colors.blanco01 }}
                                >
                                    {quejaCompleta.quejasEdad === 0 ? <i>Esta dato ya no se toma en cuenta</i> : quejaCompleta.quejasEdad}
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Fecha de resolución
                                </Label>
                                <br />
                                <Label
                                    style={{ backgroundColor: colors.blanco01, borderColor: colors.blanco01 }}
                                >
                                    {quejaCompleta.quejasFecResolucion.substring(0, 10) === "1000-01-01" ? <i>Esta dato ya no se toma en cuenta</i> : Fecha.formatDate(quejaCompleta.quejasFecResolucion)}
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Fecha que se notifico al cliente
                                </Label>
                                <br />
                                <Label
                                    style={{ backgroundColor: colors.blanco01, borderColor: colors.blanco01 }}
                                >
                                    {quejaCompleta.quejasFecNotificacion.substring(0, 10) === "1000-01-01" ? <i>Esta dato ya no se toma en cuenta</i> : Fecha.formatDate(quejaCompleta.quejasFecResolucion)}
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Sentido de la resolución
                                </Label>
                                <br />
                                <Label
                                    style={{ backgroundColor: colors.blanco01, borderColor: colors.blanco01 }}
                                >
                                    {arrayResolucion[quejaCompleta.quejasRespuesta]}
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Numero de penalización
                                </Label>
                                <br />
                                <Label
                                    style={{ backgroundColor: colors.blanco01, borderColor: colors.blanco01 }}
                                >
                                    {quejaCompleta.quejasNumPenal}
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col style={{ minWidth: device.isDesktop ? "20%" : "100%", width: device.isDesktop ? "20%" : "100%", maxWidth: device.isDesktop ? "20%" : "100%" }} >
                            <FormGroup>
                                <Label style={{ color: colors.dorado01, fontWeight: "bold" }} >
                                    Tipo de penalización
                                </Label>
                                <br />
                                <Label
                                    style={{ backgroundColor: colors.blanco01, borderColor: colors.blanco01 }}
                                >
                                    {arrayPenaliacion[quejaCompleta.quejasNumPenal]}
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                {/* <ModalFooter>

                </ModalFooter> */}
            </Modal >
        </>
    );
}

export default Quejas;